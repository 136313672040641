<template>
  <div>
    <!-- Header -->
    <div class="header bg_maroon py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">

            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon fill="#EDF0F5" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container pb-5" style="margin-top: -10rem">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card  border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-5 mt-5">
                <img src="/img/logo_new.svg" alt="" class="login-page-logo">
              </div>
              <div class="text-center text-muted mb-5 mt-5">
                <h2>LOG IN</h2>
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                              class="mb-3"
                              name="username"
                              :rules="{required: true}"
                              prepend-icon="ni ni-circle-08"
                              placeholder="Username"
                              v-model="model.username">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              name="Password"
                              :rules="{required: true, min: 6}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Password"
                              v-model="model.password">
                  </base-input>

                  <div class="text-center pb-0">
                    <base-button :loading="loader" type="submit" native-type="submit"
                                 class="mt-4 mb-0 width_100 auth_orange_button">
                      Sign in
                    </base-button>
                  </div>
                </form>
                <div class="text-center">
                  <router-link to="/forgot-password" class=" black_txt_under_orange_btn"><h3 class="mt-2 text-center">
                    Forgot password?</h3>
                  </router-link>
                </div>

                <div class="text-center" v-if="saml">
                  <a :href="samlUrl" class=" black_txt_under_orange_btn"><h3 class="mt-2 text-center">
                    Click here to login via SSO</h3>
                  </a>
                </div>

              </validation-observer>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import authHelper from "@/util/authHelper";

  export default {
    data() {
      return {
        url: process.env.VUE_APP_BASE_API_URL,
        model: {
          username: '',
          password: '',
        },
        auth_token: '',
        loader: false,
        saml: '',
        samlUrl: ''
      };
    },
    mounted() {
      let vm = this;
      let urlParams = new URLSearchParams(window.location.search);
      this.saml = urlParams.get('sso');
      if (this.saml) {
        this.samlUrl = this.url + '/saml2/' + this.saml + '/login';
      }

      if (urlParams.get('error')) {
          vm.$notify.error({
            title: 'Error',
            message: urlParams.get('error')
          });
      }

      if(!this.$store.getters.getAuthUser){
        window.Intercom("boot", {
          app_id: "jedq1yf9"
        });
      }
      vm.$store.commit('toggleSessionCheck', false)
      vm.$store.commit('updateSessionTime', 0)
    },
    methods: {
      onSubmit() {
        this.loader = true;
        let vm = this;
        this.$store.dispatch('_login', this.model).then(response => {
          if (response.data.data.isOldAddress) {
            authHelper.setIsPasswordChanged(response.data.data.isPasswordChanged);
            authHelper.setAuthToken(this.$store.getters.getAuthToken, 'subUser');
            authHelper.setRefreshToken(this.$store.getters.getRefreshToken);
            vm.$router.push({name: 'Dashboard'}).catch(()=>{});
          } else {
            this.$store.dispatch('_getSecurityQuestions').then(answers => {
              if (answers.answer_existed) {
                vm.$router.push({name: 'AnswerQuestion'});
              } else {
                vm.$router.push({name: 'SetupQuestion'})
              }
            })
          }
        }).catch(error => {
          var message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.loader = false;
          vm.$notify.error({
            title: 'Error',
            message: message
          });
        });
      },
    }
  };
</script>

<style>
  .auth_orange_button:hover {
    color: white;
  }

</style>
