import Vue from 'vue';
import Vuex from 'vuex';
import authHelper from "../util/authHelper";
import globalHelpers from "@/util/globalHelpers";
import * as chartConfigs from "@/components/Charts/config";
import _ from "lodash";
import axios from "axios";
import {nutrientsPieChart} from "@/components/Charts/config";

// import authHelper from "../util/authHelper";

Vue.use(Vuex);
import memberPortal from './modules/MemberPortal/MemberPortal'
import pipelineModule from './modules/PipelineModule'
import contentCategory from './modules/Content/ContentCategory'
import contentSubCategory from './modules/Content/ContentSubCategory'
import dynamicContentSectionModule from './modules/DynamicContentSectionModule'
import CardsAndTrophiesModule from "@/vuex/modules/CardsAndTrophiesModule";
import AdsModule from "@/vuex/modules/SuperAdmin/AdsModule";
import BariResourceModule from "@/vuex/modules/BariResourceModule";
import ManageShopModule from "@/vuex/modules/SuperAdmin/ManageShopModule";
import HowToUseAppModule from "@/vuex/modules/SuperAdmin/HowToUseAppModule";
import SupportEmailAndMessageModule from "@/vuex/modules/SuperAdmin/SupportEmailAndMessageModule";
import ManageMenuModule from "@/vuex/modules/SuperAdmin/ManageMenuModule";
import QuotesModule from "@/vuex/modules/SuperAdmin/QuotesModule";
import ManageUsersModule from "@/vuex/modules/SuperAdmin/ManageUsersModule";
import BaritasticProgramModule from "@/vuex/modules/SuperAdmin/BaritasticProgramModule";
import TipsModule from "@/vuex/modules/SuperAdmin/TipsModule";
import GroupsModule from "@/vuex/modules/SuperAdmin/GroupsModule";
import ProgramUserModule from "@/vuex/modules/SuperAdmin/ProgramUserModule";
import EventModule from "@/vuex/modules/SuperAdmin/Event/EventModule";
import LocationModule from "@/vuex/modules/SuperAdmin/Event/LocationModule";

const config = {
    // headers: { Authorization: `Bearer ${auth.getToken()}` }
    headers: {Authorization: `Bearer ${authHelper.getAuthToken('subUser')}`},
};

export const store = new Vuex.Store({

    modules:{
      memberPortal,
      pipelineModule,
      contentCategory,
      contentSubCategory,
      dynamicContentSectionModule,
      CardsAndTrophiesModule,
      AdsModule,
      ManageUsersModule,
      BariResourceModule,
      ManageShopModule,
      HowToUseAppModule,
      SupportEmailAndMessageModule,
      ManageMenuModule,
      QuotesModule,
      BaritasticProgramModule,
      TipsModule,
      GroupsModule,
      ProgramUserModule,
      EventModule,
      LocationModule,
    },
    state: {
        authToken: '',
        refreshToken: '',
        questions: '',
        pipelines: [],
        base_url: process.env.VUE_APP_BASE_API_URL,
        scheduler_base_url: process.env.VUE_APP_SCHEDULER_BASE_API_URL,
        total: [],
        sessionTime: 0,
        sessionCheck: false,
        messageTemplate: {
          templates: [],
        },
        modals: {
          termsAndConditionsModal: false,
          noEventsMessageModal: false,
          exportReportModal: false,
          exportChecklistReportModal: false,
          downloadingReport: false,
          addCurrentWeightModal: false,
          deleteChecklistNotesModal: false,
          deletePipelineNotesModal: false,
          alertsModalVisibility: false,
          lostPatientsLineGraphModal: false,
          removeModalVisibility: false,
          markAsLostModalVisibility: false,
          confirmModalVisibility: false,
          createNotesModal: false,
          deleteNotesModal: false,
          scheduleMessageVisibility:false,
          addVideo: false,
          editVideo: false,
          addSupportServiceCategory: false,
          editSupportServiceCategory: false,
          addSupportServiceSubCategory: false,
          editSupportServiceSubCategory: false,
          addSupportGroupCategory: false,
          editSupportGroupCategory: false,
          addSupportGroupSubCategory: false,
          editSupportGroupSubCategory: false,
        },
        loaders: {
          alertTemplateModal: false,
        },
        foodLogTabs: {
          tabs: [
            {
              id: 1,
              name: 'Calories',
              columnName: 'nf_calories',
              color: '#5E72E4'
            },
            {
              id: 2,
              name: 'Carb',
              columnName: 'nf_total_carbohydrate',
              color: '#FE8C19'

            },
            {
              id: 3,
              name: 'Protein',
              columnName: 'nf_protein',
              color: '#ECAF52'
            },
            {
              id: 4,
              name: 'Fat',
              columnName: 'nf_total_fat',
              color: '#FFCD16'
            },
            {
              id: 5,
              name: 'Sugar',
              columnName: 'nf_sugars',
              color: '#FF6421'
            },
            {
              id: 6,
              name: 'Fiber',
              columnName: 'nf_dietary_fiber',
              color: '#94B527'
            },
            {
              id: 7,
              name: 'Water',
              columnName: 'nf_water',
              color: '#68C0EF'
            }
          ],
          activeTabId: 1,
          activeTab: {
            id: 1,
            name: 'Calories',
            columnName: 'nf_calories',
            color: '#5E72E4'
          },
        },
        doughnutData: {
          data: [0, 0, 0, 0],
          types: {
            breakfast: 0,
            lunch: 0,
            dinner: 0,
            snacks: 0,
          },
          total: 0
        },
        topFoods: [],
        foodLogs: [],
        waterLogs: [],
        foodTypes: [],
        tempDoughnutArray: [],
        foodLogChart: {
          loading: false,
          average: 0,
          month: 'November',
          chartData: {
            datasets: [{
              label: 'FoodLog',
              data: [0],
              backgroundColor: "#6376E5",
            }],
            labels: [''],
          },
          extraOptions: chartConfigs.orangeFill,
        },
        doughnutChart: {
          chartData: {
            labels: ['Break Fast', 'Lunch', 'Dinner', 'Snacks'],
            datasets: [{
              data: [10, 10, 10, 10],
              backgroundColor: [
                '#5E72E4', '#FE8C19', '#FF6421', '#FFCD16'
              ],
            }],
          },
          extraOptions: {
            cutoutPercentage: 50,
            responsive: true,
            legend: {
              position: 'top',
            },
            animation: {
              animateScale: true,
              animateRotate: true
            }
          }
        },
      defaultPipeline: null,
      showUserCategoryFiltersSidebar: false,
      userCategoryFilters: {
        categories: [],
        defaultCategories: [],
      },
      checklistTemplateFiters:[],
      authUser: '',
      toolbarOptions: {
        height: 450,
        plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
        menubar: 'format tools table',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify| image  | outdent indent |  numlist bullist | forecolor backcolor removeformat | ltr rtl',
        content_style: 'body { font-family:Arial,Helvetica,sans-serif; font-size:16px; color: #3A3939; line-height: 1.5; }',
        fontsize_formats: '4px 6px 8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 36px',
        paste_preprocess: function (plugin, args) {
          args.content = `<div style="color: #3A3939;">${args.content}</div>`;
        },
        setup: function (editor) {
          editor.on('init', function (e,ed) {
              editor.execCommand("fontName", false, "Arial");
              editor.execCommand("fontSize", false, "16");
              editor.execCommand("foreColor", false, "#3A3939");
        });
        editor.on('paste', function(e) {
          editor.execCommand("fontName", false, "Arial");
          editor.execCommand("fontSize", false, "16");
          editor.execCommand("foreColor", false, "#3A3939");
        });
      }
      },
      toolbarOptionsWith250Height: {
        height: 250,
        plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
        menubar: '',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify| image  | outdent indent |  numlist bullist | forecolor backcolor removeformat | ltr rtl',
        content_style: 'body { font-family:Arial,Helvetica,sans-serif; font-size:16px; color: #3A3939; line-height: 1.5; }',
        fontsize_formats: '4px 6px 8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 36px',
        paste_preprocess: function (plugin, args) {
          args.content = `<div style="color: #3A3939;">${args.content}</div>`;

        },
        setup: function (editor) {
          editor.on('init', function (e,ed) {
              editor.execCommand("fontName", false, "Arial");
              editor.execCommand("fontSize", false, "16");
              editor.execCommand("foreColor", false, "#3A3939");
        });
        editor.on('paste', function(e) {
          editor.execCommand("fontName", false, "Arial");
          editor.execCommand("fontSize", false, "16");
          editor.execCommand("foreColor", false, "#3A3939");
        });
      }
      },
      isPasswordChangePage: 0,
      programUserWithSidebarMenuList : {},
      fromSubMenuManagers : [],
      source: {
        GetAllSurgeryDatePatients: '',
        SearchCheckListItems: '',
      },
      cancelRequests: {

      },
    },

    getters: {
        getSessionTime(state) {
            return state.sessionTime;
        },

        getAuthToken(state) {
            return state.authToken;
        },
        getRefreshToken(state) {
          return state.refreshToken;
        },
        getToolbarOptions(state) {
            return state.toolbarOptions;
        },
        getAuthUser(state) {
            return state.authUser;
        },
        getQuestions(state) {
            return state.questions;
        },
        getPipelines: (state) => {
            return state.pipelines
        },
        getBaseUrl: (state) => {
            return state.base_url
        },
        getSchedulerBaseUrl: (state) => {
          return state.scheduler_base_url
       },
        getMessageTemplates: (state) => {
          return state.messageTemplate.templates;
        },
        getActiveTab: (state) => {
          return state.foodLogTabs.activeTab;
        },
        getDefaultPipeline: (state) => {
          return state.defaultPipeline;
        },
        getUserCategories: (state) => {
          return state.userCategoryFilters.categories;
        },
        getChecklistTemplateFilters: (state) => {
          return state.checklistTemplateFiters;
        },
        getDefaultUserSubCategories: (state) => {
          return state.userCategoryFilters.defaultCategories;
        },
        getIsPasswordChangePage: (state) => {
          return state.isPasswordChangePage;
        },
        getSidebarMenuListFromSubMenuManagers: (state) => {
          return state.fromSubMenuManagers;
        },
        getSidebarMenuListFromProgramUser: (state) => {
          return state.programUserWithSidebarMenuList;
        },

    },

    mutations: {

        updateSessionTime(state, payload) {
          state.sessionTime = payload
        },

        toggleSessionCheck(state, payload) {
          state.sessionCheck = payload
        },

        toggleExportReportModal(state, payload) {
          state.modals.exportReportModal = payload
        },

        toggleExportChecklistReportModal(state, payload) {
          state.modals.exportChecklistReportModal = payload
        },

        toggleDownloadReport(state, payload) {
          state.modals.downloadingReport = payload
        },

        toggleTermsAndConditionsModal(state, payload) {
          state.modals.termsAndConditionsModal = payload
        },

        toggleNoEventsMessageModal(state, payload) {
          state.modals.noEventsMessageModal = payload
        },

        showEditSupportGroupSubCategoryModal(state, payload) {
            state.modals.editSupportGroupSubCategory = true;
        },

        hideEditSupportGroupSubCategoryModal(state, payload) {
            state.modals.editSupportGroupSubCategory = false;
        },

        showAddCurrentWeightModal(state, payload) {
            state.modals.addCurrentWeightModal = true;
        },

        hideAddCurrentWeightModal(state, payload) {
            state.modals.addCurrentWeightModal = false;
        },

        showAddSupportGroupSubCategoryModal(state, payload) {
            state.modals.addSupportGroupSubCategory = true;
        },

        hideAddSupportGroupSubCategoryModal(state, payload) {
            state.modals.addSupportGroupSubCategory = false;
        },
        showEditSupportGroupCategoryModal(state, payload) {
            state.modals.editSupportGroupCategory = true;
        },

        hideEditSupportGroupCategoryModal(state, payload) {
            state.modals.editSupportGroupCategory = false;
        },

        showAddSupportGroupCategoryModal(state, payload) {
            state.modals.addSupportGroupCategory = true;
        },

        hideAddSupportGroupCategoryModal(state, payload) {
            state.modals.addSupportGroupCategory = false;
        },

        showEditSupportServiceSubCategoryModal(state, payload) {
            state.modals.editSupportServiceSubCategory = true;
        },

        hideEditSupportServiceSubCategoryModal(state, payload) {
            state.modals.editSupportServiceSubCategory = false;
        },

        showAddSupportServiceSubCategoryModal(state, payload) {
            state.modals.addSupportServiceSubCategory = true;
        },

        hideAddSupportServiceSubCategoryModal(state, payload) {
            state.modals.addSupportServiceSubCategory = false;
        },

        toggleLostPatientsLineGraphModal(state, val) {
          state.modals.lostPatientsLineGraphModal = val;
        },

        showEditSupportServiceCategoryModal(state, payload) {
            state.modals.editSupportServiceCategory = true;
        },

        hideEditSupportServiceCategoryModal(state, payload) {
            state.modals.editSupportServiceCategory = false;
        },

        showAddSupportServiceCategoryModal(state, payload) {
            state.modals.addSupportServiceCategory = true;
        },

        hideAddSupportServiceCategoryModal(state, payload) {
            state.modals.addSupportServiceCategory = false;
        },

        showEditVideoModal(state, payload) {
            state.modals.editVideo = true;
        },

        hideEditVideoModal(state, payload) {
            state.modals.editVideo = false;
        },

        showAddVideoModal(state, payload) {
            state.modals.addVideo = true;
        },

        hideAddVideoModal(state, payload) {
            state.modals.addVideo = false;
        },

        setAuthToken(state, token) {
            state.authToken = token;
        },

        setRefreshToken(state, token) {
          state.refreshToken = token;
        },

        setAuthUser(state, user) {
            state.authUser = user;
        },
        setAuthUserPasswordChanged(state, isPasswordChanged) {
          if(typeof state.authUser == 'object' && !Array.isArray(state.authUser) && Object.keys(state.authUser).length > 0 && state.authUser.type != 'super_admin') {
            state.authUser.is_password_changed = isPasswordChanged;
          }
        },
        deleteAuthUser(state) {
            state.authUser = '';
        },

        setQuestions(state, payload) {
            state.questions = payload;
        },

        deleteAuthToken(state, payload) {
            authHelper.deleteAuthToken('subUser');
        },

        setMessageTemplates(state, payload) {
          state.messageTemplate.templates = payload;
        },

        showRemoveModal(state, payload) {
            state.modals.removeModalVisibility = true;
        },

        hideRemoveModal(state, payload) {
          state.modals.removeModalVisibility = false;
        },

        showAlertsModal(state, payload) {
          state.modals.alertsModalVisibility = true;
        },

        showConfirmModal(state, payload) {
          state.modals.confirmModalVisibility = true;
        },

        hideAlertsModal(state, payload) {
          state.modals.alertsModalVisibility = false;
        },

        showMarkAsLostModal(state, payload) {
          state.modals.markAsLostModalVisibility = true;
        },

        hideMarkAsLostModal(state, payload) {
          state.modals.markAsLostModalVisibility = false;
        },

        hideConfirmModal(state, payload) {
          state.modals.confirmModalVisibility = false;
        },

        showEditScheduleMessageModal(state, payload) {
          state.modals.scheduleMessageVisibility = true;
        },

        hideEditScheduleMessageModal(state, payload) {
          state.modals.scheduleMessageVisibility = false;
        },

        showUCFS(state) {
          state.showUserCategoryFiltersSidebar = true;
        },

        hideUCFS(state) {
          state.showUserCategoryFiltersSidebar = false;
        },

        setFoodLogTab(state, id) {
          state.foodLogTabs.activeTabId = id;
          state.foodLogTabs.activeTab = state.foodLogTabs.tabs.find(tab => tab.id === id);
        },

        resetFoodStates(state, payload) {
          state.topFoods = [];
          state.tempDoughnutArray = [];
          state.doughnutData.total = 0;
        },

        updateMealSections(state, sections) {
          state.foodTypes = []
          let foodType = {}
          sections.forEach(item => {
            foodType = {
              'title': item.title.toLowerCase() ?? 'other',
              'isData': 0
            }
            state.foodTypes.push(foodType)
          })
        },

        updateFoodTypes(state, data) {
          state.doughnutData = {
            data: [0, 0, 0, 0],
              types: {
              breakfast: 0,
                lunch: 0,
                dinner: 0,
                snacks: 0,
            },
            total: 0
          }
          state.foodTypes = []
          const foodTypes = {}
          const foodData = []
          let foodType = {}
          data.forEach(item => {
            foodTypes[item.title.toLowerCase() ?? 'other'] = 0
            foodType = {
              'title': item.title.toLowerCase() ?? 'other',
              'isData': 0
            }
            state.foodTypes.push(foodType)
            foodData.push(0)
          })
          state.doughnutData = {
            data: state.doughnutData.data.concat(foodData),
            types: {...foodTypes},
            total: 0
          }
        },

        updateFoodLogChart(state, foodLogs) {
            let data = [], labels = [], sum = 0, dayCount = 0;
            let activeTab = state.foodLogTabs.activeTab;
            this.commit('resetFoodStates');
            for (const interval of foodLogs.interval) {
              // checking if food log exists on a given date.
              const foodLogFound = foodLogs.nutrition_meals.filter(foodLog => foodLog.date === interval);
              if (foodLogFound.length > 0) {
                let result = foodLogFound.map(food => {
                  if (isNaN(food.user_serving_Size) || food.user_serving_Size === '') food.user_serving_Size = 1;
                  if (isNaN(food[activeTab.columnName]) || food[activeTab.columnName] === '') food[activeTab.columnName] = 0;
                  return +food[activeTab.columnName] * +food.user_serving_Size;
                }).reduce((a, b) => {
                  if (isNaN(a)) a = 0;
                  if (isNaN(b)) b = 0
                  return +a + +b;
                }, 0);

                // bar chart logic is complete.
                processTopFoods(foodLogFound, activeTab, state)
                processDoughnutChart(foodLogFound, activeTab, state);

                data.push(Math.round(result));
                sum = sum + result;
                dayCount = result!== 0 ? dayCount + 1 : dayCount;
              } else {
                data.push(0);
              }
              labels.push(interval);
            }
            processTopFoods(null, activeTab, state);
            processDoughnutChart(null, activeTab, state);
            state.foodLogChart.average = globalHelpers.average(sum, dayCount);
            state.foodLogChart.chartData = {
              datasets: [{
                label: activeTab.name,
                data: data,
                backgroundColor: activeTab.color,
              }],
              labels: labels,
            }
          },

        updateWaterLogChart(state, waterLogs) {
          let data = [], labels = [] , sum = 0, dayCount = 0;
          let activeTab = state.foodLogTabs.activeTab;
          this.commit('resetFoodStates');
          for (let interval of waterLogs.interval) {
            const water = waterLogs.waterLogs.find(w => w.date === interval);
            water ? (data.push(Math.round(water.total)),sum+=Math.round(water.total),(Math.round(water.total) > 0 ? dayCount +=1 : dayCount)) : (data.push(0),sum+=0);
            labels.push(interval);
          }
          state.foodLogChart.average = globalHelpers.average(sum, dayCount);
          state.foodLogChart.chartData = {
            datasets: [{
              label: activeTab.name,
              data: data,
              backgroundColor: activeTab.color,
            }],
            labels: labels,
          }
        },

        setUserSubcategories(state , payload) {
          state.userCategoryFilters.categories = payload.categories;
          state.userCategoryFilters.defaultCategories = payload.defaultCategories;
        },

        setChecklistTemplateFilters(state , payload) {
          state.checklistTemplateFiters = payload.data;
        },

        setUserSubCategoriesDefault (state,payload){
          state.userCategoryFilters.categories[payload.index].state = payload.data;
        },

        setIsPasswordChangePage(state, payload) {
          state.isPasswordChangePage = payload;
        },

        setSidebarMenuList(state, payload) {
          state.fromSubMenuManagers = payload.fromSubMenuManagersTable;
          state.programUserWithSidebarMenuList = payload.fromPUTable;
        },

        setSidebarMenuStatus(state, payload) {
          state.fromSubMenuManagers.map(function(value) {
            if (value.id == payload) {
              value.status = !value.status;
            }
          });
        },

        setPipelineStages(state, payload) {
          let index = state.pipelines.findIndex(pipeline => pipeline.id == payload.pipeline_id);
          if (index > -1) {
            state.pipelines[index].stages = payload.stages;
          }
        },
        /**
         * Set the cancelation request for ongoing request
         * @param State - state object
         * @param key - key
         */
      setCancellationToken(state, key) {
          if (state.cancelRequests[key]) {
            state.cancelRequests[key].cancel();
          }
          const CancelToken = axios.CancelToken;
          state.cancelRequests[key] = CancelToken.source();
      },
    },

    actions: {
      getPipelinesWithStagesAction: (context) => {
        return new Promise((resolve, reject) => {
          axios.get(context.state.base_url + '/api/pipelines').then((response) => {
            context.commit('toggleSessionCheck', true)
            context.commit('updateSessionTime', 0)
            context.state.pipelines = response.data.data.data;
            context.state.defaultPipeline = response.data.data.default;
            resolve(response);
          }).catch(error => {
            reject(error)
          });
        })
      },

      _getSubUser(context, payload) {
        return new Promise((resolve, reject) => {
          axios.get(context.state.base_url + '/api/user').then((response) => {
            context.commit('toggleSessionCheck', true)
            context.commit('updateSessionTime', 0)
            context.commit('setAuthUser', response.data);
            authHelper.setSelfServiceCheck(response.data?.self_service ?? 0)
            resolve(response);
          }).catch(error => {
            reject(error);
          });
        })
      },

      _login(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(context.state.base_url + '/api/login', payload).then((response) => {
            context.commit('toggleSessionCheck', true)
            context.commit('updateSessionTime', 0)
            context.commit('setAuthToken', response.data.data.token);
            context.commit('setRefreshToken', response.data.data.refreshToken);
            context.commit('toggleNoEventsMessageModal', response.data.data.isEventExist ?? false);
            authHelper.deleteManagePatientsSavedSearch()
            authHelper.deleteEventCalendarSavedSearch()
            resolve(response);
          }).catch(error => {
            reject(error);
          });
        })
      },

      _logout(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(context.state.base_url + '/api/logout').then((response) => {
            context.commit('toggleSessionCheck', false)
            context.commit('updateSessionTime', 0)
            context.commit('deleteAuthToken');
            context.commit('deleteAuthUser');
            authHelper.deleteManagePatientsSavedSearch();
            authHelper.deleteEventCalendarSavedSearch()
            authHelper.deleteSelfServiceCheck();
            authHelper.removeManageUsersSuperAdminSavedSearch();
            authHelper.removeActiveTabInCustomizePipeline();
            authHelper.deleteRefreshToken();
            resolve(response);
          }).catch(error => {
            reject(error);
          });
        })
      },

      _answerSecurityQuestion(context, payload) {
        const config = {
          headers: {Authorization: `Bearer ${context.getters.getAuthToken}`}
        };
        return new Promise((resolve, reject) => {
          axios.post(context.state.base_url + '/api/security-answer',
            payload,
            config
          ).then((response) => {
            context.commit('toggleSessionCheck', true)
            context.commit('updateSessionTime', 0)
            authHelper.setIsPasswordChanged(response.data.data.isPasswordChanged);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
        })
      },

      _getSecurityQuestions(context, payload) {
        const config = {
          headers: {Authorization: `Bearer ${context.getters.getAuthToken}`}
        };
        return new Promise((resolve, reject) => {
          axios.get(context.state.base_url + '/api/security-questions', config).then((response) => {
            context.commit('toggleSessionCheck', true)
            context.commit('updateSessionTime', 0)
            context.commit('setQuestions', response);
            resolve(response.data.data);
          }).catch(error => {
            reject(error)
          });
        });
      },

      _getMessageTemplates(context, payload) {
        return new Promise((resolve, reject) => {
          axios.get(`${context.state.base_url}/api/alerts/message-templates`).then((response) => {
            context.commit('toggleSessionCheck', true)
            context.commit('updateSessionTime', 0)
            let templates = response.data.data;
            context.commit('setMessageTemplates', templates)
            resolve(templates);
          }).catch(error => {
            reject(error)
          });
        });
      },

      _addMessageTemplate(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${context.state.base_url}/api/alerts/message-templates/store`,
            payload,
          ).then((response) => {
            context.commit('toggleSessionCheck', true)
            context.commit('updateSessionTime', 0)
            resolve(response.data.data);
          }).catch(error => {
            reject(error)
          });
        });
      },

      _editMessageTemplates(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${context.state.base_url}/api/alerts/message-templates/update`,
            payload,
          ).then((response) => {
            context.commit('toggleSessionCheck', true)
            context.commit('updateSessionTime', 0)
            resolve(response.data.data);
          }).catch(error => {
            reject(error)
          });
        });
      },

      _deleteMessageTemplates(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${context.state.base_url}/api/alerts/message-templates/delete`,
            payload,
          ).then((response) => {
            context.commit('toggleSessionCheck', true)
            context.commit('updateSessionTime', 0)
            resolve(response.data.data);
          }).catch(error => {
            reject(error)
          });
        });
      },

      _getInternalNotes(context, payload) {
        return new Promise((resolve, reject) => {
          axios.get(`${context.state.base_url}/api/internal-notes?page=${payload.page}&patient_id=${payload.patient_id}&pagination=${payload.pagination}&note_type=${payload.note_type}`).then((response) => {
            context.commit('toggleSessionCheck', true)
            context.commit('updateSessionTime', 0)
            resolve(response.data.data);
          }).catch(error => {
            reject(error)
          });
        });
      },

      _addInternalNote(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${context.state.base_url}/api/internal-notes/store`,
            payload,
          ).then((response) => {
            context.commit('toggleSessionCheck', true)
            context.commit('updateSessionTime', 0)
            resolve(response.data.data);
          }).catch(error => {
            reject(error)
          });
        });
      },

      _deleteInternalNote(context, payload) {

        return new Promise((resolve, reject) => {
          axios.post(`${context.state.base_url}/api/internal-notes/delete`,
            payload,
          ).then((response) => {
            context.commit('toggleSessionCheck', true)
            context.commit('updateSessionTime', 0)
            resolve(response.data.data);
          }).catch(error => {
            reject(error)
          });
        });
      },

      _addNote(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${context.state.base_url}/api/checklist/notes`,
            payload,
          ).then((response) => {
            context.commit('toggleSessionCheck', true)
            context.commit('updateSessionTime', 0)
            resolve(response.data.data);
          }).catch(error => {
            reject(error)
          });
        });
      },

      _deleteChecklistNote(context, payload) {
        return new Promise((resolve, reject) => {
          axios.delete(`${context.state.base_url}/api/checklist/notes/${payload.id}`,
          ).then((response) => {
            context.commit('toggleSessionCheck', true)
            context.commit('updateSessionTime', 0)
            resolve(response.data.data);
          }).catch(error => {
            reject(error)
          });
        });
      },

      _getFoodLogs(context, payload) {
        let vm = this;
        return new Promise((resolve, reject) => {
          axios.get(context.state.base_url + `/api/nutrition/food-logs?user_id=${payload.patient_id}&month=${payload.month}&year=${payload.year}`).then(response => {
            context.commit('toggleSessionCheck', true)
            context.commit('updateSessionTime', 0)
            let foodLogs = response.data.data
            context.state.foodLogs = response.data.data;
            context.commit('updateFoodTypes', foodLogs.mealTypesOrder)
            context.commit('updateFoodLogChart', foodLogs)
            resolve();
          }).catch(error => {
            reject(error);
          })
        })
      },

      _getMacroNutrients({commit, state}, payload) {
        const params = {
          patient_id: payload.patient_id,
          month: payload.month,
          year: payload.year
        }
        return new Promise((resolve, reject) => {
          axios.get(state.base_url + `/api/nutrition/macro-nutrients`, {params})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      },

      _getWaterLogs(context, payload) {
        let vm = this;
        return new Promise((resolve, reject) => {
          axios.get(context.state.base_url + `/api/reports/water-logs?patientId=${payload.patientId}&month=${payload.month}&year=${payload.year}`).then(response => {
            context.commit('toggleSessionCheck', true)
            context.commit('updateSessionTime', 0)
            let waterLogs = response.data.data;
            context.state.waterLogs = response.data.data;
            context.commit('updateWaterLogChart', waterLogs);
            resolve();
          }).catch(error => {
            reject(error);
          })
        })
      },

      _updateSortList(context, {contentMenu, payload}) {
        let vm = this
        return new Promise((resolve, reject) => {
          axios.post(context.state.base_url + `/api/content/${contentMenu}/update-indexes`, payload)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => reject(error))
        })
      },

      _getSidebarMenuList({commit, getters}) {
        let vm = this
        return new Promise((resolve, reject) => {
          axios.get(getters.getBaseUrl + `/api/sidebar-menu`)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              commit('setSidebarMenuList', {
                'fromSubMenuManagersTable': response.data.data.fromSubMenuManagers,
                'fromPUTable': response.data.data.fromPUsTable
              });
              commit('setAuthUser', response.data.data.authUser);
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _fetchSupportServices(context) {
        return new Promise((resolve, reject) => {
          axios.get(context.state.base_url + `/api/content/support-service`)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => reject(error))
        })
      },

      _getSupportService(context, payload) {
        return new Promise((resolve, reject) => {
          axios.get(context.state.base_url + `/api/content/support-service/${payload.id}`)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => reject(error))
        })
      },

      _deleteSupportService(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${context.state.base_url}/api/content/support-service/destroy`, payload)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => {
            reject(error)
          })
        })
      },

      _updateStatusSupportService(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${context.state.base_url}/api/content/support-service/toggle-status`, payload)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => {
            reject(error)
          })
        })
      },

      _getAllSupportServiceCategories(context) {
        return new Promise((resolve, reject) => {
          axios.get(context.state.base_url + `/api/content/support-service-category/all`)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => reject(error))
        })
      },

      _fetchRelevantSupportServiceSubCategories(context, payload) {
        return new Promise((resolve, reject) => {
          axios.get(context.state.base_url + `/api/content/support-service-sub-category/by-category-id?categoryId=${payload.id}`)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => {
            reject(error)
          })
        })
      },

      _storeSupportService(context, {config, formData}) {
        return new Promise((resolve, reject) => {
          axios.post(context.state.base_url + `/api/content/support-service/store`, formData, {config})
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _updateSupportService(context, {config, formData}) {
        return new Promise((resolve, reject) => {
          axios.post(context.state.base_url + `/api/content/support-service/update`, formData, {config})
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _fetchSupportServiceCategories(context, config) {
        return new Promise((resolve, reject) => {
          axios.get(context.state.base_url + `/api/content/support-service-category`, config)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _updateStatusSupportServiceCategories(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${context.state.base_url}/api/content/support-service-category/toggle-status`, payload)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => {
            reject(error)
          })
        })
      },

      _deleteSupportServiceCategory(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${context.state.base_url}/api/content/support-service-category/destroy`, payload)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => {
            reject(error)
          })
        })
      },

      _storeSupportServiceCategory(context, {config, formData}) {
        return new Promise((resolve, reject) => {
          axios.post(context.state.base_url + `/api/content/support-service-category/store`, formData, {config})
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _updateSupportServiceCategory(context, {config, formData}) {
        return new Promise((resolve, reject) => {
          axios.post(context.state.base_url + `/api/content/support-service-category/update`, formData, {config})
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _getSupportServiceCategory(context, payload) {
        return new Promise((resolve, reject) => {
          axios.get(context.state.base_url + `/api/content/support-service-category/${payload.id}`)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _fetchSupportServiceSubCategories(context, config) {
        return new Promise((resolve, reject) => {
          axios.get(context.state.base_url + `/api/content/support-service-sub-category`, config)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _updateStatusSupportServiceSubCategories(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${context.state.base_url}/api/content/support-service-sub-category/toggle-status`, payload)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => {
            reject(error)
          })
        })
      },

      _deleteSupportSubServiceCategory(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${context.state.base_url}/api/content/support-service-sub-category/destroy`, payload)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => {
            reject(error)
          })
        })
      },

      _storeSupportServiceSubCategory(context, {config, formData}) {
        return new Promise((resolve, reject) => {
          axios.post(context.state.base_url + `/api/content/support-service-sub-category/store`, formData, {config})
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _getSupportServiceSubCategory(context, payload) {
        return new Promise((resolve, reject) => {
          axios.get(context.state.base_url + `/api/content/support-service-sub-category/${payload.id}`)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _updateSupportServiceSubCategory(context, {config, formData}) {
        return new Promise((resolve, reject) => {
          axios.post(context.state.base_url + `/api/content/support-service-sub-category/update`, formData, {config})
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _fetchBlogWebsite(context) {
        return new Promise((resolve, reject) => {
          axios.get(context.state.base_url + `/api/content/blog-website`)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _updateBlogWebsite(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${context.state.base_url}/api/content/blog-website/update`, payload)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _fetchEducationWebsite(context) {
        return new Promise((resolve, reject) => {
          axios.get(context.state.base_url + `/api/content/education-website`)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _updateEducationWebsite(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${context.state.base_url}/api/content/education-website/update`, payload)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _fetchBMIWebsite(context) {
        return new Promise((resolve, reject) => {
          axios.get(context.state.base_url + `/api/content/bmi-website`)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _updateBMIWebsite(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${context.state.base_url}/api/content/bmi-website/update`, payload)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _fetchDiabetesWebsite(context) {
        return new Promise((resolve, reject) => {
          axios.get(context.state.base_url + `/api/content/diabetes-website`)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _updateDiabetesWebsite(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${context.state.base_url}/api/content/diabetes-website/update`, payload)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _getOnDemandMessages({commit, state}, payload) {
        commit('setCancellationToken', 'getOnDemandMessages');
        const params = {
          page: payload.page,
          perPage: payload.perPage
        }
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/on-demand-messages`, {
              params,
              cancelToken: state.cancelRequests['getOnDemandMessages'].token,
            })
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _fetchOnDemandMessage({commit, state}, {id}) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/on-demand-messages/${id}`)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _storeOnDemandMessage({commit, state}, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/on-demand-messages`, payload)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _fetchTags({commit, state}) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/user-category/1`)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _fetchShopWebsite(context) {
          return new Promise((resolve, reject) => {
            axios.get(context.state.base_url + `/api/content/shop-website`)
              .then(response => {
                context.commit('toggleSessionCheck', true)
                context.commit('updateSessionTime', 0)
                resolve(response)
              })
              .catch(error => reject(error))
          })
      },

      _updateShopWebsite(context, payload) {
          return new Promise((resolve, reject) => {
            axios.post(`${context.state.base_url}/api/content/shop-website/update`, payload,{
              enctype: "multipart/form-data"
            })
              .then(response => {
                context.commit('toggleSessionCheck', true)
                context.commit('updateSessionTime', 0)
                resolve(response)
              })
              .catch(error => reject(error))
          })
      },

      _fetchNewsletterWebsite(context) {
        return new Promise((resolve, reject) => {
          axios.get(context.state.base_url + `/api/content/newsletter-website`)
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _updateNewsletterWebsite(context, {config, formData}) {
        return new Promise((resolve, reject) => {
          axios.post(`${context.state.base_url}/api/content/newsletter-website/update`, formData, {config})
            .then(response => {
              context.commit('toggleSessionCheck', true)
              context.commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _fetchSupportGroups({commit, state}) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/content/support-group`)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => reject(error))
        })
      },

      _getSupportGroup({commit, state}, payload) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/content/support-group/${payload.id}`)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => reject(error))
        })
      },

      _fetchSupportGroupMenuDataRequest({commit, state}) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/content/support-group/menu-data/get`)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => reject(error))
        })
      },

      _storeSupportGroupMenuDataRequest({commit, state}, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/content/support-group/menu-data/store`, payload)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => reject(error))
        })
      },

      _deleteSupportGroup({commit, state}, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/content/support-group/destroy`, payload)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => {
            reject(error)
          })
        })
      },

      _updateStatusSupportGroup({commit, state}, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/content/support-group/toggle-status`, payload)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => {
            reject(error)
          })
        })
      },

      _getAllSupportGroupCategories({commit, state}) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/content/support-group-category/all`)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => reject(error))
        })
      },

      _fetchRelevantSupportGroupSubCategories({commit, state}, payload) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/content/support-group-sub-category/by-category-id?categoryId=${payload.id}`)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => {
            reject(error)
          })
        })
      },

      _storeSupportGroup({commit, state}, {config, formData}) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/content/support-group/store`, formData, {config})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _updateSupportGroup({commit, state}, {config, formData}) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/content/support-group/update`, formData, {config})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _fetchSupportGroupCategories({commit, state}, config) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/content/support-group-category`, config)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _updateStatusSupportGroupCategories({commit, state}, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/content/support-group-category/toggle-status`, payload)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => {
            reject(error)
          })
        })
      },

      _deleteSupportGroupCategory({commit, state}, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/content/support-group-category/destroy`, payload)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => {
            reject(error)
          })
        })
      },

      _storeSupportGroupCategory({commit, state}, {config, formData}) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/content/support-group-category/store`, formData, {config})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _getSupportGroupCategory({commit, state}, payload) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/content/support-group-category/${payload.id}`)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _updateSupportGroupCategory({commit, state}, {config, formData}) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/content/support-group-category/update`, formData, {config})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _fetchSupportGroupSubCategories({commit, state}, config) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/content/support-group-sub-category`, config)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _updateStatusSupportGroupSubCategories({commit, state}, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/content/support-group-sub-category/toggle-status`, payload)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => {
            reject(error)
          })
        })
      },

      _deleteSupportGroupSubCategory({commit, state}, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/content/support-group-sub-category/destroy`, payload)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            }).catch(error => {
            reject(error)
          })
        })
      },

      _storeSupportGroupSubCategory({commit, state}, {config, formData}) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/content/support-group-sub-category/store`, formData, {config})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _getSupportGroupSubCategory({commit, state}, payload) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/content/support-group-sub-category/${payload.id}`)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _updateSupportGroupSubCategory({commit, state}, {config, formData}) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/content/support-group-sub-category/update`, formData, {config})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _getAllChecklistNotes({commit, state}, {patient_id}) {
        const params = {
          patient_id
        }
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/checklist/get-all-notes`, {params})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _getLostPatientsLineGraph({commit, state}, { timeGap }) {
        const params = {
          'time-gap': timeGap
        }
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/seminar2surgery/lost-patients-line-graph-stats`, { params })
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _updateChecklistItemDetail({commit, state}, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/checklists/update-detail`, payload)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _addPatientCurrentWeight({commit, state}, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/weight-logs/store`, payload)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Get Post Op Patients
       * @param commit
       * @param state
       * @param params - Includes duration, sortBy, sortByDir, page, perPage
       * @returns {Promise<unknown>}
       * @private
       */
      _postOpPatientsReport({commit, state}, params) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/seminar2surgery/post-op-patients`, {params})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Get Surgery Date Patients
       * @param commit
       * @param state
       * @param params - Includes duration, sortBy, sortByDir, page, perPage
       * @returns {Promise<unknown>}
       * @private
       */
      _getSurgeryDateReportPatients({commit, state}, params) {
        return new Promise((resolve, reject) => {
          if (state.source.GetAllSurgeryDatePatients) {
            state.source.GetAllSurgeryDatePatients.cancel()
          }
          const CancelToken = axios.CancelToken;
          state.source.GetAllSurgeryDatePatients = CancelToken.source();
          axios.get(`${state.base_url}/api/seminar2surgery/surgery-date-patients`, {params, cancelToken: state.source.GetAllSurgeryDatePatients.token})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Get the Surgery Date Patients Excel
       * @param commit
       * @param state
       * @param config - Includes responseType: "blob"
       * @returns {Promise<unknown>}
       * @private
       */
      _surgeryDatePatientsExcel({commit, state}, config) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/seminar2surgery/surgery-date-patients/excel`, config)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Get Not Connected Patients
       * @param commit
       * @param state
       * @param params - Includes duration, sortBy, sortByDir, page, perPage
       * @returns {Promise<unknown>}
       * @private
       */
      _notConnectedPatientsReport({commit, state}, params) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/seminar2surgery/not-connected-patients`, {params})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Get the Not Connected Patients Excel
       * @param commit
       * @param state
       * @param config - Includes responseType: "blob"
       * @returns {Promise<unknown>}
       * @private
       */
      _notConnectedPatientsExcel({commit, state}, config) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/seminar2surgery/not-connected-patients/excel`, config)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _getWeightReminderMessage({commit, state}) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/weight-reminder-message`)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _updateWeightReminderMessage({commit, state}, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/weight-reminder-message/update`, payload)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Get the Post Op Patients Excel
       * @param commit
       * @param state
       * @param config - Includes responseType: "blob"
       * @returns {Promise<unknown>}
       * @private
       */
      _postOpPatientsExcel({commit, state}, config) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/seminar2surgery/post-op-patients/excel`, config)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Update the Terms and Condition Check for Program user
       * @param context - Contains the commit and state object
       * @param payload - Payload for the API
       * @returns {Promise<unknown>}
       */
      _updateTermsAndConditionsCheck({commit, state}, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/program-user-terms-check`, payload)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Get the Pipeline Patient Excel Report
       * @param context - Contains the commit and state object
       * @param config - Configuration for the API
       * @returns {Promise<unknown>}
       */
      _pipelinePatientsExcel({commit, state}, config) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/pipeline-patients-excel`, config)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Get Meal sections listing
       * @param context - Contains the commit and state object
       * @param params - Parameters for the API
       * @returns {Promise<unknown>}
       * @private
       */
      _getMealSections({commit, state}, params) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/food-logs/sections`, {params})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              commit('updateMealSections', response.data.data)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _getPatientPdfReport({commit, state}, params) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/patients-report/pdf?patientId=${params.patientId}&type=${params.type}`,
            { responseType: 'blob' })
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Get Checklist Items from other templates
       * @param context - Contains the commit and state object
       * @param params - Parameters for the API
       * @returns {Promise<unknown>}
       * @private
       */
      _getOtherTemplateItems({commit, state}, params) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/checklist/templates/other`, {params})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Update the Automatic message text field status
       * @param context - Contains the commit and state object
       * @param payload - Payload for the API
       * @returns {Promise<unknown>}
       */
      _updateAutomaticMessageFieldStatus({commit, state}, payload) {
        return new Promise((resolve, reject) => {
          axios.put(`${state.base_url}/api/AutomaticMessages/message-field-status/update`, payload)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Update the Automatic message text field status
       * @param context - Contains the commit and state object
       * @param payload - Payload for the API
       * @returns {Promise<unknown>}
       */
      _updateWeightReminderMessageFieldStatus({commit, state}, payload) {
        return new Promise((resolve, reject) => {
          axios.put(`${state.base_url}/api/AutomaticMessages/weight-reminder/message-field-status/update`, payload)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Get all pipelines only
       * @param context - Contains the commit and state object
       * @returns {Promise<unknown>}
       * @private
       */
      _getAllPipelines({commit, state}) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/all-pipelines`)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Get all patients count by pipeline
       * @param context - Contains the commit and state object
       * @param params - Parameters for the API
       * @returns {Promise<unknown>}
       * @private
       */
      _getPatientByPipeline({commit, state}, params) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/pipeline-patients-by-stage`, {params})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Get all patients average days spent in each stage by pipeline
       * @param context - Contains the commit and state object
       * @param params - Parameters for the API
       * @returns {Promise<unknown>}
       * @private
       */
      _getPatientAverageDaysByPipeline({commit, state}, params) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/get-pipeline-patient-average-days`, {params})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Get all active checklist templates
       * @param context - Contains the commit and state object
       * @returns {Promise<unknown>}
       * @private
       */
      _getAllActiveChecklistTemplates({commit, state}) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/checklist/templates/allActive`)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Get the Pipeline Patient Excel Report
       * @param context - Contains the commit and state object
       * @param config - Configuration for the API
       * @returns {Promise<unknown>}
       */
      _pipelinePatientsAverageDaysExcel({commit, state}, config) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/pipeline-patients-excel/by-average-days`, config)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },


      /**
       * Get the Lost Patients Excel Report
       * @param context - Contains the commit and state object
       * @param config - Configuration for the API
       * @returns {Promise<unknown>}
       */
      _lostPatientsExcel({commit, state}, config) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/seminar2surgery/lost-patients/excel`, config)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Update dashboard popup status
       * @param context - Contains the commit and state object
       * @returns {Promise<unknown>}
       */
      _updateDashboardPopupStatus({commit, state}) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/dashboard-popup-status/update`)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       *
       * @param context - Contains the commit and state object
       * @param params -  Params for the API
       * @returns {Promise<unknown>}
       */
      _getPatientEvents({commit, state}, params) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/patient-events`, {params})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _getRequestedEvents({commit, state}, params) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/patient-events/events`, {params})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _getRequestedPatientsForEvents({commit, state}, params) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/patient-events/patients`, {params})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      _storePatientsEvent({commit, state}, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/patient-events/store`, payload)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Get program filters
       * @param context - Contains the commit and state object
       * @param params - Parameters for the API
       * @returns {Promise<unknown>}
       * @private
       */
      _getFilters({commit, state}, params) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/pre-load-filters`, {params})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Get checklist and pipeline notes
       * @param context - Contains the commit and state object
       * @param params - Parameters for the API
       * @returns {Promise<unknown>}
       * @private
       */
      _getChecklistAndPipelineNotes({commit, state}, params) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/notes/checklist-and-pipeline`, {params})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Get checklist notes by stage
       * @param commit
       * @param state
       * @param params
       * @returns {Promise<unknown>}
       * @private
       */
      _getChecklistNotes({commit, state}, params) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/checklist/notes/by-stage`, {params})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Update checklist in report
       * @param commit
       * @param state
       * @param data
       * @returns {Promise<unknown>}
       * @private
       */
      _updateCheckedByInReport({commit, state}, data) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/checklists/update_checked_by_in_report`, data)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Update program timezone
       * @param commit
       * @param state
       * @param payload
       * @returns {Promise<unknown>}
       * @private
       */
      _updateProgramTimezone({commit, state}, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/event-calendar/location/timezone`, payload)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Get Stalled Patient Children Element
       * @param state
       * @param params
       * @returns {Promise<unknown>}
       * @private
       */
      _getStalledPatientsChildren({state}, params) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/seminar2surgery/stalled-patients/children`, {params})
            .then(response => {
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Update the Automatic message text field status
       * @param context - Contains the commit and state object
       * @param payload - Payload for the API
       * @returns {Promise<unknown>}
       */
      _updateImportAdminEventsStatus({commit, state}, payload) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.scheduler_base_url}/api/event-calendar/update-import-status`, payload)
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },

      /**
       * Get checklist item names
       * @param context - Contains the commit and state object
       * @param config - Payload for the API
       * @returns {Promise<unknown>}
       */
      _getChecklistItemNamesForChecklistReport({commit, state}, config) {
        return new Promise((resolve, reject) => {
          if (state.source.SearchCheckListItems) {
            state.source.SearchCheckListItems.cancel()
          }
          const CancelToken = axios.CancelToken;
          state.source.SearchCheckListItems = CancelToken.source();
          axios.get(`${state.base_url}/api/seminar2surgery/checklist-item-names`, {...config, cancelToken: state.source.SearchCheckListItems.token})
            .then(response => {
              commit('toggleSessionCheck', true)
              commit('updateSessionTime', 0)
              resolve(response)
            })
            .catch(error => reject(error))
        })
      }
    }
})

function processTopFoods(food = null, activeTab, state) {
  if (food != null) { // if food is not null then add to top 10 foods.
    state.topFoods = [...state.topFoods, ...food]
  } else { // if food is null then sort it

      let topFoodsCount = []
      state.topFoods.forEach(topFood => {
          topFoodsCount[topFood.item_name.toLowerCase()] = topFoodsCount[topFood.item_name.toLowerCase()] != undefined ? topFoodsCount[topFood.item_name.toLowerCase()] + 1 : 1;
      });

    state.topFoods = _.map(_.groupBy(state.topFoods, function (value) {
      return value.item_name.toLowerCase();
    }), (o, idx) => {
      return {
        name: activeTab.name,
        item_name: idx,
        count: topFoodsCount[idx],
        value: _.sumBy(o, function (value) {
          if (isNaN(value.user_serving_Size) || value.user_serving_Size === '') value.user_serving_Size = 1;
          if (isNaN(value[activeTab.columnName]) || value[activeTab.columnName] === '') value[activeTab.columnName] = 0;
          return +value[activeTab.columnName] * +value.user_serving_Size
        })
      }
    });

    state.topFoods = _.sortBy(state.topFoods, o => o.value).reverse();
    state.topFoods = state.topFoods.slice(0, 10);
  }
}

function processDoughnutChart(food, activeTab, state) {
  if (food != null) { // if food is not null
    state.tempDoughnutArray = [...state.tempDoughnutArray, ...food]
  } else { // else plot the doughnut chart
    for (let food of state.tempDoughnutArray) {
      if (isNaN(food[activeTab.columnName]) || food[activeTab.columnName] === '') food[activeTab.columnName] = 0;
      if (isNaN(food.user_serving_Size) || food.user_serving_Size === '') food.user_serving_Size = 1;
      state.doughnutData.total = +state.doughnutData.total + (+food[activeTab.columnName] * +food.user_serving_Size);
      let type = food.type ? food.type.toLowerCase() : food.meal_type ? food.meal_type.name.toLowerCase() : 'other';
      state.doughnutData.types[type] = +state.doughnutData.types[type] + (+food[activeTab.columnName] * +food.user_serving_Size);
    }

    let objKeys = Object.keys(state.doughnutData.types);
    let data = [];
    let percentage = 0;

    // Resetting the isData flag for each food types
    state.foodTypes.forEach(foodType => {
        foodType.isData = 0
    })

    for (let key of objKeys) {
      percentage = (state.doughnutData.types[key] / state.doughnutData.total) * 100;
      if (percentage) {
        state.foodTypes.forEach(foodType => {
          if (foodType.title == key) foodType.isData = 1 // Condition to check if data exist for this food type
        })
      }
      percentage = Math.round(percentage);
      data.push(percentage);
    }
    const backgroundColors = ['#5E72E4', '#FE8C19', '#ECAF52', '#58c0e4', '#FF6421', '#94B527', '#8e5576', '#D1B490', '#508484', '#A3A380']
    state.doughnutChart.chartData = {
      labels: Object.keys(state.doughnutData.types),
      datasets: [{
        data: data,
        backgroundColor: backgroundColors
      }],
    };
  }
}
