<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <div class="pl-2">
      <route-back-button/>
    </div>
    <h1>{{ HeadingString }} Event</h1>
    <el-form v-loading="loader.form || loader.location" :model="EventForm" ref="eventForm" @submit="handleSubmit">

      <card class="mt-4">

        <div class="row">
          <div class="col-12 col-md-6">
            <h4 class="form-label">Event Name*</h4>
            <el-form-item
              prop="name"
              :rules="rules.name"
            >
              <base-input
                v-model="EventForm.name"
              ></base-input>
            </el-form-item>
          </div>
          <div class="col-12 col-md-6">
            <h4 class="form-label">Event Type*</h4>
            <base-input
            >
              <el-select v-model="EventForm.type" :default-first-option="true">
                <el-option value="1" label="Fixed Date"></el-option>
                <el-option value="2" label="Weekly"></el-option>
              </el-select>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <h4 class="form-label">Venue*</h4>
            <el-form-item
              prop="venue_type"
            >
              <base-input
              >
                <el-select v-model="EventForm.venue_type" :default-first-option="true">
                  <el-option value="" label="All"></el-option>
                  <el-option value="1" label="In-Person"></el-option>
                  <el-option value="2" label="Virtual"></el-option>
                </el-select>
              </base-input>
            </el-form-item>
          </div>
          <div class="col-12 col-md-6">
            <h4 class="form-label">Weight Loss Plan*</h4>
            <el-form-item
              prop="weight_loss_type"
            >
              <base-input
              >
                <el-select v-model="EventForm.weight_loss_type" :default-first-option="true">
                  <el-option value="" label="All"></el-option>
                  <el-option value="1" label="Surgical"></el-option>
                  <el-option value="2" label="Non-Surgical"></el-option>
                </el-select>
              </base-input>
            </el-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h4 class="form-label">Event Description*</h4>
            <el-form-item
              prop="description"
              :rules="rules.description"
            >
              <editor
                v-model="EventForm.description"
                :api-key=editorApiKey
                :init=editorOption
              />
            </el-form-item>
          </div>
        </div>

        <div class="row mt-1">

          <div class="row col-6 m-0">
            <div class="col-6 p-0 m-0">
              <h4 class="form-label">Start Time*</h4>
              <el-form-item
                prop="startTime"
                :rules="rules.startTime"
                class="custom-time-picker"
              >
                <base-time-picker
                    v-model="EventForm.startTime"
                    @blur="()=>validateField('startTime')"
                    @input="()=>validateField('startTime')"
                />
              </el-form-item>
            </div>
            <div class="col-6 p-0 m-0">
              <h4 class="form-label">End Time*</h4>
              <el-form-item
                prop="endTime"
                :rules="rules.endTime"
              >
                <base-time-picker
                    v-model="EventForm.endTime"
                    @blur="()=>validateField('endTime')"
                    @input="()=>validateField('endTime')"
                />
              </el-form-item>
            </div>
          </div>

          <div class="col-6">
            <h4 class="form-label">Date*</h4>
            <el-form-item prop="date" name="Date" :rules="{required: true}">
              <div>
                <b-input-group class="mb-3">
                  <el-input
                    style="height: 40px; width: calc(100% - 60px); border-top-right-radius: 0px;border-bottom-right-radius: 0px"
                    v-mask="'##/##/####'"
                    id="example-input"
                    v-model="EventForm.date"
                    type="text"
                    placeholder="MM/DD/YYYY"
                    autocomplete="off"
                    @blur="handleDate"
                  ></el-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      :min="today"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="example-input"
                      :date-format-options="{ month: '2-digit' ,day: '2-digit', year: 'numeric' }"
                      @context="onContextDate"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </el-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <h4 class="form-label">Location*</h4>
            <base-input>
              <el-select class="form-group" v-model="EventForm.location" @change="handleChange">
                <template v-for="location in locations">
                  <el-option :value="String(location.id)" :label="location.name" :disabled="!location.status"
                             :key="location.id"></el-option>
                </template>
                <el-option value="" label="Other"></el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-12 col-md-6">
            <h4 class="form-label">Location Address*</h4>
            <el-form-item
              prop="address"
              :rules="rules.address"
            >
              <base-input
                class="form-group"
                v-model="EventForm.address"
                :disabled="!addressEditable"
              ></base-input>
            </el-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <h4 class="form-label">Status*</h4>
            <base-input>
              <el-select v-model="EventForm.status" :default-first-option="true">
                <el-option value="1" label="Active"></el-option>
                <el-option value="0" label="Inactive"></el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-12 col-md-6">
            <h4 class="form-label">Email</h4>
            <base-input
              v-model="EventForm.email"
              type="email"
            ></base-input>
          </div>
        </div>

        <div class="row">
          <h4 class="form-label ml-3">Groups to Remind</h4>
          <el-checkbox-group
            class="w-100 row m-0"
            v-model="EventForm.stages"
          >
            <div class="col-sm-3 col-12">
              <div class="stage_box w-100 pt-3 pb-2 px-3">
                <el-checkbox label="25">Considering Surgery</el-checkbox>
              </div>
            </div>

            <div class="col-sm-3 col-12">
              <div class="stage_box w-100 pt-3 pb-2 px-3">
                <el-checkbox label="50">Pre-Op</el-checkbox>
              </div>
            </div>
            <div class="col-sm-3 col-12">
              <div class="stage_box w-100 pt-3 pb-2 px-3">
                <el-checkbox label="75">Post-Op</el-checkbox>
              </div>
            </div>
            <div class="col-sm-3 col-12">
              <div class="stage_box w-100 pt-3 pb-2 px-3">
                <el-checkbox label="150">Non-Surgical</el-checkbox>
              </div>
            </div>
          </el-checkbox-group>
        </div>
        <!--        Change the slot content below-->
        <div class="pl-1 pb-2 pt-4">
          <el-checkbox v-model="EventForm.reminder">24 Hour Reminder</el-checkbox>
          <el-checkbox v-model="EventForm.five_minute_earlier_reminder">15 Minute Reminder</el-checkbox>
        </div>
        <div class="row" v-if="EventForm.five_minute_earlier_reminder==1">
          <div class="col-12 col-md-12">
            <h4 class="form-label">Custom 15 Minute Reminder Message*</h4>
            <el-form-item
              prop="custom_reminder_message"
              :rules="rules.custom_reminder_message"
            >
              <base-input
                @input="eventNameEdited"
                class="form-group"
                v-model="EventForm.custom_reminder_message"
              ></base-input>
            </el-form-item>
          </div>
        </div>
      </card>
      <el-button class="btn-primary-action font-weight-bolder px-5 mb-4" type="submit"
                 :disabled="loader.form"
                 @click="handleSubmit"
      >{{ buttonText }}
      </el-button>
    </el-form>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import {store} from "@/vuex/store";
import moment from "moment";

export default {
  name: "EventForm",
  components: {RouteBackButton: BackButton},
  props: {
    newFlag: {
      Boolean,
      Default: true,
      Description: 'Whether this form will be used for edit or add'
    },

  },
  computed: {
    HeadingString() {
      return (this.isCopy) ? 'Copy' : (this.isNew && !this.$route.params.id) ? 'Add New ' : 'Update'
    },
    buttonText() {
      return (this.isCopy) ? 'Copy Event' : (this.isNew && !this.$route.params.id) ? 'Add Event' : 'Update Event'
    },
    addressEditable() {
      return this.EventForm.location === '';
    }
  },
  watch:{
    'EventForm.name':function (newVal){
      if(!this.eventNameEditted){
        if (newVal == "") {
          this.$set(this.EventForm, 'custom_reminder_message', `<Event Name> will be starting soon. Click on calendar in the main menu of the app for details.`);
        } else {
          this.$set(this.EventForm, 'custom_reminder_message', `${this.EventForm.name} will be starting soon. Click on calendar in the main menu of the app for details.`);
        }
      }
    },
    'timezone': function(newValue) {
      if (!newValue) {
        this.$notify.error({
            title: 'Event',
            message: 'Please contact Baritastic support to update your timezone.'
        });
        this.$router.push({name: 'EventList'})
      }
    }
  },
  data() {
    let custom5MinuteReminderMessageValidation = (rule, value, callback) => {
      if (this.EventForm.five_minute_earlier_reminder) {
        if (this.EventForm.custom_reminder_message == '' || this.EventForm.custom_reminder_message.trim() == '') {
          callback(new Error('Please type Custom 5 Minute Reminder Message'));
        }
      }
      callback()
    };
    let customValidateForEndTime = (rule, value, callback) => {
      if (this.EventForm.startTime !== '') {
        let startTime = moment(this.EventForm.startTime,'hh:mm A');
        let endTime = moment(value,'hh:mm A');
        if(startTime >= endTime)
          callback(new Error('End Time must be greater than Start Time'));
      }
      callback()
    };
    let customValidateForStartTime = (rule, value, callback) => {
      if (this.EventForm.endTime !== '') {
        let startTime = moment(value,'hh:mm A');
        let endTime = moment(this.EventForm.endTime,'hh:mm A');
        if(startTime >= endTime)
          callback(new Error('Start Time must be less than End Time'));
      }
      callback()
    };
    return {
      today: moment().format('YYYY-MM-DD'),
      editorApiKey: process.env.VUE_APP_EDITOR_API_KEY,
      editorOption: store.state.toolbarOptions,
      isNew: true,
      isCopy: false,
      loader: {
        form: false,
        location: false
      },
      locations: [],
      eventNameEditted:false,
      EventForm: {
        name: '',
        type: '1',
        description: '',
        startTime: '',
        endTime: '',
        date: '',
        stages: [],
        reminder: false,
        five_minute_earlier_reminder: false,
        location: '',
        address: '',
        email: '',
        status: '1',
        venue_type: '',
        weight_loss_type: '',
        custom_reminder_message:'<event_name> will be starting soon. Click on calendar in the main menu of the app for details.',
        admin_imported: 0,
      },
      copyingEvent: null,
      rules: {
        name: [
          {required: true, message: 'Please enter Event name', trigger: 'blur'},
        ],
        description: [
          {required: true, message: 'Please type Description', trigger: 'blur'},
        ],
        startTime: [
          {required: true, message: 'Please pick a starting time', trigger: 'change'},
          {validator: customValidateForStartTime, trigger: 'blur'}
        ],
        endTime: [
          {required: true, message: 'Please pick a ending time', trigger: 'change'},
          {validator: customValidateForEndTime, trigger: 'blur'}
        ],
        custom_reminder_message: [
          {validator: custom5MinuteReminderMessageValidation, trigger: 'blur'}
        ],
        date: [
          {required: true, message: 'Please select a date', trigger: 'change'}
        ],
        address: [
          {required: true, message: 'Please type address', trigger: 'blur'}
        ],
        venue_type: [
          {required: true, message: 'Please select a venue type', trigger: 'blur'}
        ],
        weight_loss_type: [
          {required: true, message: 'Please select a weight loss type', trigger: 'blur'}
        ],
      },
      timezone: undefined
    }
  },
  methods: {
    handleDate(e) {
      if (moment(e.target.value).format('MM/DD/YYYY') == 'Invalid date') {
        this.EventForm.date = '';
      }
      if (moment(this.EventForm.date) < moment(this.today)) {
        this.EventForm.date = moment(this.today).format('MM/DD/YYYY');
      }
    },

    onContextDate(ctx) {
      if (ctx.selectedYMD != '') {
        this.EventForm.date = ctx.selectedFormatted
      }
    },

    setValues(event) {
      this.EventForm.name = event.event_title
      this.EventForm.type = '' + event.type
      this.EventForm.description = event.event_description
      this.EventForm.startTime = event.time
      this.EventForm.endTime = event.end_time
      this.EventForm.date = moment(event.date).format('MM/DD/YYYY');
      let stages = event.stage.split(',')
      stages = stages.filter((value, index, arr) => {
        return !!value
      })
      for (const stage of stages) {
        this.EventForm.stages.splice(0, 0, stage)
      }
      this.EventForm.reminder = !!(event.push_notification)
      this.EventForm.five_minute_earlier_reminder = event.five_minute_earlier_reminder ==1 ? true : false;
      this.EventForm.location = event.location_id ?? ''
      // handle trashy old dashboard string for a foreign key
      this.isLocationIdValid()
      this.EventForm.address = event.location
      this.EventForm.email = event.email
      this.EventForm.status = (event.status) ? '1' : '0'
      this.EventForm.venue_type = event.venue_type ?? ''
      this.EventForm.weight_loss_type = event.weight_loss_type ?? ''
      if (event.id) {
        if (event.custom_reminder_message && event.custom_reminder_message.trim()!="") {
          this.eventNameEditted = true;
          this.EventForm.custom_reminder_message = event.custom_reminder_message;
        } else {
          this.eventNameEditted = false;
          this.EventForm.custom_reminder_message = '<Event Name> will be starting soon. Click on calendar in the main menu of the app for details.';
        }
      } else {
        this.eventNameEditted = false;
        this.EventForm.custom_reminder_message = '<Event Name> will be starting soon. Click on calendar in the main menu of the app for details.';
      }
    },

    eventNameEdited(){
      this.eventNameEditted = true;
    },

    isLocationIdValid() {
      let valid = false
      for (const location of this.locations) {
        if (this.EventForm.location == location.id) valid = true
      }
      if (!valid) this.EventForm.location = ''
    },

    handleChange(id) {
      if (id !== '') {
        for (const location of this.locations) {
          if (location.id == id && location.status) {
            this.EventForm.address = location.address
          }
        }
      } else {
        this.EventForm.address = '';
      }
    },

    handleSubmit() {
      let validForm = 'form valid'
      this.$refs['eventForm'].validate((valid) => {
        if (!valid) {
          validForm = false;
          return false;
        }
      })
      if(this.copyingEvent && JSON.stringify(this.copyingEvent) === JSON.stringify(this.EventForm)){
        validForm = false;
        this.$notify.error({
          title: 'Duplicate',
          message: 'Please change some values to continue..'
        })
        return;
      }
      if (validForm) {
        if(!this.EventForm.five_minute_earlier_reminder){
          this.EventForm.custom_reminder_message = null;
        }
        if (this.$route.params.id && !this.isCopy)
          this.editEventRequest()
        else
          this.createEventRequest()
      } else {
        this.$notify.error({
          title: 'Missing Field',
          message: 'Enter missing Fields'
        })
      }
    },

    createEventRequest() {
      this.loader.form = true
      let params = {...this.EventForm};
      params['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const config = {
        data: Object.assign({_method: 'PUT'}, params),
      }
      axios.post(this.$store.getters.getSchedulerBaseUrl + '/api/event-calendar/create', config.data, config)
        .then(() => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.$notify.success({
            title: 'Success',
            message: 'Event created successfully'
          })
          this.$router.go(-1)
        })
        .catch(() => {
          this.$notify.error({
            title: 'Failure',
            message: 'Something went wrong try again later'
          })
        })
        .finally(() => this.loader.form = false)
    },

    editEventRequest() {
      this.loader.form = true
      let params = {...this.EventForm};
      params['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const config = {
        data: Object.assign({_method: 'PATCH'}, params),
      }
      axios.post(this.$store.getters.getSchedulerBaseUrl + `/api/event-calendar/update/${this.$route.params.id}`
        , config.data, config)
        .then(() => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.$notify.success({
            title: 'Success',
            message: 'Event updated successfully'
          })
          this.$router.go(-1)
        })
        .catch(() => {
          this.$notify.error({
            title: 'Failure',
            message: 'Something went wrong try again later'
          })
        })
        .finally(() => this.loader.form = false)
    },

    fetchEventRequest() {
      this.loader.form = true
      axios.get(this.$store.getters.getBaseUrl + `/api/event-calendar/${this.$route.params.id}`)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.setValues(response.data.data);
          if(this.isCopy){
            this.copyingEvent = {...this.EventForm};
          }
        })
        .catch((error) => {
          this.$notify.error({
            title: 'Error',
            message: 'Event might have been deleted'
          })
          this.$router.go(1)
        })
        .finally(() => this.loader.form = false)
    },

    fetchLocationsRequest() {
      this.loader.location = true
      axios.get(this.$store.getters.getBaseUrl + '/api/event-calendar/location')
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.locations = response.data.data.locations.data
          this.timezone = response.data.data.timezone;
        })
        .catch(() => {
          this.$notify.error({
            title: 'Error',
            message: 'Location might have been deleted'
          })
          this.$router.go(1)
        })
        .finally(() => this.loader.location = false)
    },
    validateField(name) {
      this.$refs.eventForm.validateField(name)
    }
  },
  mounted() {
    this.fetchLocationsRequest()
    if (this.newFlag === false) {
      this.isNew = false
    }
    this.EventForm.type = '1'
    if (this.event) {
      this.setValues(this.event)
    }
    if (this.$route.params.id) {
      this.fetchEventRequest()
    }
    if (this.$route.params.copyFlag) {
      this.isCopy = true
    }
  }
}
</script>

<style scoped>

.form-label {
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
}

.stage_box {
  width: fit-content;
  height: fit-content;
  border-radius: 4px;
  border: 1px solid #DEE2E6;
}

.el-button:active, .el-button:focus {
  color: #FFFFFF;
  border-radius: 6px
}

.custom-time-picker {
  width: 95% !important;
}
</style>

<style>
.el-form-item__content {
  line-height: unset !important;
}

</style>
