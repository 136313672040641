import { extend } from "vee-validate";
import { ext,required,confirmed } from "vee-validate/dist/rules";
import moment from "moment";

extend("custom_confirmed",{
  ...confirmed,
  message: (field,params) => {
    let name = `${field.substring(0, 1).toUpperCase()}${field
      .substring(1)
      .replaceAll("_", " ")}`;
    return `${name} doesn't match.`
  }
})

extend("custom_required", {
  ...required,
  message: (field, params) => {
    let name = `${field.substring(0, 1).toUpperCase()}${field
      .substring(1)
      .replaceAll("_", " ")}`;
    return `${name} is required.`
  },
});
extend("custom_url", {
  validate:(value)=>{
    return value && /^((http|https|ftp):\/\/)([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,63}(\/\S*)?$/.test(value);
  },
  message: (field, params) => {
    if(field.toLowerCase() == "url"){
      return `Please enter valid URL.`
    }
    return `Please enter valid ${field}.`
  },
});
extend("extensions", {
  validate: (value, args) => {
    if (value) {
      return ext.validate(value, args);
    }
    return true;
  },
  message: (field, params) => {
    let typesArray = [];
    for (let index in params) {
      if (!isNaN(index)) {
        typesArray.push(params[index].toUpperCase());
      }
    }
    let typesMessage =
      typesArray.splice(0, typesArray.length - 1).join(",") +
      " and " +
      typesArray[typesArray.length - 1];
    return `The ${field} must be ${typesMessage}.`;
  },
});

extend("validate_start_time", {
  validate: async (value, args) => {
    if (args[0] != '' && args[1] != '') {
      return validateTime(args[0], args[1])
    }
  },
  message : (field, params) => {
    return 'Start Time must be less than End Time'
  }
})

extend("validate_end_time", {
  validate: async (value, args) => {
    if (args[0] != '' && args[1] != '') {
      return validateTime(args[0], args[1])
    }
  },
  message : (field, params) => {
    return 'End Time must be greater than Start Time'
  }
})

function validateTime(start, end) {
  let startTime = moment(start,'hh:mm A')
  let endTime = moment(end,'hh:mm A')
  return startTime < endTime
}

extend("min_dimensions", {
  validate: async (value, args) => {
    let min_width = args[0] || 0;
    let min_height = args[1] || 0;
    let ratio = args[2] || 0;
    let validate = true;
    if(value){
    if (value && Array.isArray(value)) {
      for (let file of value) {
        validate = await calculateMinDimensions(
          file,
          min_width,
          min_height,
          ratio
        );
      }
    } else if(value && !Array.isArray(value)) {
        validate = await calculateMinDimensions(
            value,
            min_width,
            min_height,
            ratio
          );
    }
  }
    return validate;
  },
  message: (field, params) => {
    let min_width = params[0];
    let min_height = params[1];
    return `Please upload ${field} with minimum ${min_width} * ${min_height}.`;
  },
});

function calculateMinDimensions(file, min_width, min_height, ratio) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    //Read the contents of Image File.
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      //Initiate the JavaScript Image object.
      let image = new Image();
      //Set the Base64 string return from FileReader as source.
      image.src = e.target.result;

      //Validate the File Height and Width.
      image.onload = function () {
        let height = this.height;
        let width = this.width;
        if (
          height < min_height ||
          width < min_width ||
          (ratio > 0 && (width / height).toFixed(1) != ratio)
        ) {
          resolve(false);
        }
        resolve(true);
      };
    };
  });
}

