<template>
  <div>
    <div class="modals">
      <export-checklist-report-modal @exportReport="exportAllPatientsReport"></export-checklist-report-modal>
    </div>
    <div class="d-flex align-items-center flex-wrap">

      <div class="d-inline-block mr-1 mb-2">
        <base-input class="d-inline-block" v-model="request.api.firstName"
                    label="First Name"
                    placeholder="Search..." @change="filterChanged" type="search" @search="filterChanged">
        </base-input>
      </div>

      <div class="d-inline-block mr-1 mb-2">
        <base-input class="d-inline-block ml-2" v-model="request.api.lastName"
                    label="Last Name"
                    placeholder="Search..." @change="filterChanged" type="search" @search="filterChanged">
        </base-input>
      </div>
      <div class="d-inline-block mr-1 mb-2">
        <base-input label="Current Stage" class="d-inline-block ml-2">
          <p @click="clearStage" class="d-inline-block float-right mt--4 mr-2 mb-0 clear-txt">Clear</p>
          <el-select
            class="select-primary pagination-select"
            v-model=request.api.currentStage
            placeholder="Current Stage"
            @change="filterChanged"
          >
            <el-option
              class="select-primary"
              v-for="(stage , index) in response.stages"
              :key="`${stage.label}_${stage.value}`"
              :label="stage.label"
              :value="`${stage.value}`"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="d-inline-block mr-1 mb-2">
        <base-input label="Completed By" class="d-inline-block ml-2">
          <p @click="clearCompletedBy" class="d-inline-block float-right mt--4 mr-2 mb-0 clear-txt">Clear</p>
          <el-select
            class="select-primary pagination-select"
            v-model=request.api.completedBy
            placeholder="Completed By"
            @change="filterChanged"
          >
            <el-option
              class="select-primary"
              v-for="(checklistItem , index) in completedByOptionsForFilter"
              :key="`${checklistItem.value}_${index}_cbf`"
              :label="checklistItem.label"
              :value="checklistItem.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="d-inline-block mr-1 mb-2">
        <base-input label="Checklist Item" class="d-inline-block ml-2">
          <p @click="clearChecklistItem" class="d-inline-block float-right mt--4 mr-2 mb-0 clear-txt">Clear</p>
          <el-select
            class="select-primary pagination-select"
            v-model=request.api.currentChecklistItem
            placeholder="Checklist Item"
            @change="filterChanged"
            remote
            filterable
            :loading="loaders.checklistOptions"
            :remote-method="(search) => getChecklistItemNamesForChecklistReport(search)"
          >
            <el-option
              class="select-primary"
              v-for="(checklistItem , index) in response.checklistItems"
              :key="index"
              :label="checklistItem"
              :value="checklistItem"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="d-inline-block mr-1 mb-2 mt--4">
        <label class="form-control-label">Date Range (Completed)</label>
        <el-date-picker
          class="w-100 date-ranger-picker"
          v-model="request.api.date_range"
          type="daterange"
          format="MM/dd/yyyy"
          range-separator="-"
          start-placeholder="Start date"
          end-placeholder="End date"
          @change="filterChanged">
        </el-date-picker>
      </div>

      <base-button type="secondary"
                   class="d-inline-block py-2 ml-auto align-self-center font-weight-normal filter-button-width"
                   @click="$store.commit('showUCFS')">
        <img class="mr-2" src="/img/svg/filters.svg" alt="Filters"> Filter
        <el-badge :value="getFiltersCount" class="mark filter-count-badge p-0" type="warning"></el-badge>
      </base-button>
    </div>
    <hr>
    <div class="row">
      <div class="col-12 d-flex" >
        <div class="table-responsive">
          <el-table :data="response.tblAllPatientsData"
                    v-loading="loading"
                    row-key="id"
                    class="table-responsive table-flush table-stalled-patient"
                    header-row-class-name="thead-light"
                    @sort-change="sortMethod"
          >
            <el-table-column label="PATIENT NAME" min-width="110px" max-width="110px">
              <template slot-scope="scope">
                <el-tooltip
                  class="item" effect="dark"
                  :content="hoverPatientInfo(scope.row)"
                  placement="bottom-start"
                  :visible-arrow="false"
                >
                  <router-link :to="{name: 'checklists', params: {id: scope.row.patient_id}}">
                    <span class="color-maroon">{{ scope.row.first_name + ' ' + scope.row.last_name }}</span>
                  </router-link>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="CHECKLIST ITEM" prop="item" min-width="260px" sortable="custom">
            </el-table-column>
            <el-table-column label="COMPLETED BY" prop="due_date" min-width="150px">
              <template slot-scope="scope">
                  <el-select class="input_height" v-model="scope.row['checked_by']" size="small" @change="updateCheckedBy(scope.row)">
                    <el-option
                      v-for="(option, index) in completedByOptions"
                      class="select-danger"
                      :value="option.value"
                      :label="option.label"
                      :key="`${option.value}_${index}_cb`">
                    </el-option>
                  </el-select>
                </template>
            </el-table-column>
            <el-table-column label="COMPLETED" prop="completed" min-width="110px" sortable="custom">
              <template slot-scope="scope">
                {{ formatDate(scope.row.completed) }}
              </template>
            </el-table-column>
            <el-table-column label="SURGERY DATE" prop="surgery_date" min-width="110px" sortable="custom">
              <template slot-scope="scope">
                {{
                  showSurgeryDate(scope.row.surgery_date)
                }}
              </template>
            </el-table-column>
            <el-table-column label="START DATE" prop="consult_date" min-width="110px" sortable="custom">
              <template slot-scope="scope">
                {{
                  formatDate(scope.row.consult_date)
                }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <seminar-surgery-footer :pagination="pagination" @changePage="changePage" @changedPageLength="changedPageLength"/>
      </div>
    </div>
  </div>
</template>

<script>
import SeminarSurgeryFooter from "./SeminarSurgeryFooter";
import {Option, Select, Table, TableColumn} from "element-ui";
import moment from 'moment';
import Helper from "@/util/globalHelpers";
import ExportChecklistReportModal from "@/views/Pages/Seminar2Surgery/ExportChecklistReportModal";
import Swal from 'sweetalert2';

export default {
  name: "AllPatients",
  props :  ['tab'],
  components: {
    SeminarSurgeryFooter,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    ExportChecklistReportModal
  },

  data(){
    let currentDate = new Date();
    let before90DaysDate = new Date();
    before90DaysDate.setDate(currentDate.getDate() - 90);
    return {
      loading : false,
      modals : {

      },
      loaders : {
        exportAllPatientsLoader : false,
        checklistOptions: false,
      },
      request : {
        api: {
          patientId:'',
          firstName:'',
          lastName:'',
          currentStage:'',
          currentChecklistItem:'',
          checklistItemId:'',
          sortBy:'days_left',
          sortByDir:'ASC',
          date_range: [before90DaysDate, currentDate],
          completedBy: '',
          surgeryDate: '',
          surgeryDateDuration: '',
        },
        checklistItemOptions: {
          search: ''
        }
      },
      response : {
        tblAllPatientsData : [],
        stages: [],
        checklistItems: [],
        categories: [],
        defaultCategories: [],
      },
      tableColumns: [
        {
          prop: 'patient_name',
          label: 'PATIENT NAME',
          minWidth: 200,
          align:'center'
        },
        {
          prop: 'last_used',
          label: 'LAST USED',
          minWidth: 200,
        },
        {
          prop: 'checklist_item',
          label: 'CHECKLIST ITEM',
          minWidth: 200,
        },
        {
          prop: 'complete',
          label: 'COMPLETE',
          minWidth: 200,
        },
        {
          prop: 'start_date',
          label: 'START DATE',
          minWidth: 200,
        },
      ],
      pagination : {
        currentPage : 1,
        perPage : 20,
        page:1,
        lastPage:1,
      },
      filtersCount : 0,
      selectedPatientTags: [],
      completedByOptionsForFilter: [
        {
          value: '',
          label: 'Anyone',
        },
        {
          value: 'Program',
          label: 'Program',
        },
        {
          value: 'Patient',
          label: 'Patient',
        }
      ],
      completedByOptions: [
        {
          value: 'Program',
          label: 'Program',
        },
        {
          value: 'Patient',
          label: 'Patient',
        }
      ]
    }
  },

  activated() {
    this.getChecklistItemNamesForChecklistReport('');
    this.getFiltersData();
    let vm = this;
    this.$root.$on('UserSubCategoryFilterCount',(data)=>{
      vm.filtersCount = data;
    });
    this.$root.$on('applyUserSubCategoryFilters',(data)=>{
      vm.filtersCount = data.filtersCount;
      this.pagination.page = 1;
      vm.selectedPatientTags = data.obj
      vm.request.api.surgeryDate = data.surgeryDate;
      vm.request.api.surgeryDateDuration = data.surgeryDateDuration;
      vm.getAllPatients(data.obj);
    });
  },

  deactivated() {
    this.$root.$off('UserSubCategoryFilterCount');
    this.$root.$off('applyUserSubCategoryFilters');
  },

  computed: {
    getFiltersCount () {
      return this.filtersCount ?? 0;
    }
  },
  methods : {

    hoverPatientInfo(row){
      let returnable = ''
      returnable = (row.hasOwnProperty('first_name') === true) ? returnable+row.first_name + ' ' + row.last_name : returnable+''
      returnable = (row.hasOwnProperty('email') === true && row.email !== '' && row.email != null) ? returnable+', '+row.email : returnable+''
      returnable = (row.hasOwnProperty('phone') === true && row.phone !== '' && row.phone != null) ? returnable+', '+row.phone : returnable+''
      if (row.hasOwnProperty('dob') === true && row.dob !== '' && row.dob != null){
        if (!isNaN(Date.parse(row.dob))){
          const date = new Date(row.dob+"T00:00:00")
          returnable = returnable+', '+Helper.extractAndFormatDate(date.toDateString(),false)
        }
      }else {
        returnable = returnable+''
      }
      returnable = (row.hasOwnProperty('mrn') === true && row.mrn !== '' && row.mrn != null) ? returnable + ', ' + row.mrn : returnable + ' '
      return returnable
    },

    getFiltersData(){
      let vm = this;
      vm.loading = true;
      axios.get(this.$store.getters.getBaseUrl + '/api/seminar2surgery/filters-data?tab=4'
      ).then((response) => {
        vm.request.api.currentStage = response.data.data.default_stage ? response.data.data.default_stage : '';
        vm.response.stages = response.data.data.stages;
        vm.response.categories = response.data.data.categories;
        vm.response.defaultCategories = response.data.data.defaultCategories;
        this.$store.commit('setUserSubcategories',{categories:vm.response.categories,defaultCategories:vm.response.defaultCategories })
        let obj = [];
        vm.response.categories.forEach((category,index)=>{
          var i = vm.response.defaultCategories.findIndex(dc=>{
            if(dc.user_category_id == category.id){
              return true;
            }
          });
          if(i!=-1){
            obj.push(vm.response.defaultCategories[i].user_sub_category_id);
          }
        });
        vm.selectedPatientTags = obj;
        vm.getAllPatients(obj);
      }).catch(error => {
        console.log(error.response)
      });
    },

    filterChanged(){
      this.pagination.page = 1;
      this.createCategoriesArrAndApiCall();
    },

    clearStage(){
      this.request.api.currentStage = '';
      this.pagination.page = 1;
      this.createCategoriesArrAndApiCall();
    },

    clearChecklistItem(){
      this.request.api.currentChecklistItem = '';
      this.pagination.page = 1;
      this.getChecklistItemNamesForChecklistReport('');
      this.createCategoriesArrAndApiCall();
    },

    clearCompletedBy(){
      this.request.api.completedBy = '';
      this.pagination.page = 1;
      this.createCategoriesArrAndApiCall();
    },

    clearUserCategories(){
      this.createCategoriesArrAndApiCall();
    },

    changePage(page){
      if(this.pagination.page != page){
        this.pagination.page = page;
        this.createCategoriesArrAndApiCall();
      }
    },

    changedPageLength(pageLength) {
      this.pagination.page = 1;
      this.pagination.perPage = pageLength;
      this.createCategoriesArrAndApiCall();
    },

    createCategoriesArrAndApiCall(){
      let userSubCategoriesIds = [];
      this.response.categories.forEach(category => {
        if(category.state && !isNaN(category.state)){
          userSubCategoriesIds.push(category.state);
        }
      });
      this.selectedPatientTags = userSubCategoriesIds;
      this.getAllPatients(userSubCategoriesIds);
    },

    getAllPatients(userSubCategories = null){
      let vm = this;
      vm.loading = true;
      const dateRangeObject = vm.getDateRangeObject()
      this.$store.commit('setCancellationToken', 'getAllPatients');
      axios.get(this.$store.getters.getBaseUrl + '/api/seminar2surgery/all-patients', {
          params: {
            user_sub_categories: userSubCategories && userSubCategories.length > 0 ? userSubCategories : null,
            from_date: dateRangeObject[0],
            to_date: dateRangeObject[1],
            firstName: vm.request.api.firstName,
            lastName: vm.request.api.lastName,
            currentStage: vm.request.api.currentStage,
            checklist: vm.request.api.currentChecklistItem,
            completedBy: vm.request.api.completedBy,
            surgeryDate: vm.request.api.surgeryDate,
            surgeryDateDuration: vm.request.api.surgeryDateDuration,
            sortBy: vm.request.api.sortBy,
            sortByDir: vm.request.api.sortByDir,
            page: vm.pagination.page,
            perPage: vm.pagination.perPage
          },
          cancelToken: this.$store.state.cancelRequests['getAllPatients'].token,
        }
      ).then((response) => {
        vm.response.tblAllPatientsData = [];
        vm.response.tblAllPatientsData = [...response.data.data.data];
        vm.pagination.currentPage = response.data.data.current_page;
        vm.pagination.lastPage = response.data.data.last_page;
        vm.pagination.perPage = response.data.data.per_page;
        vm.pagination.page = response.data.data.current_page;
        vm.pagination.total = response.data.data.total;
        vm.loading = false;
      }).catch(error => {
        if(!axios.isCancel(error)){
          console.log(error.response);
        }
      });
    },

    /**
     * Get date range object for filtering
     * @returns {string[]|*[]}
     */
    getDateRangeObject() {
      if (this.request.api.date_range && this.request.api.date_range.length) {
        const fromDate = moment(this.request.api.date_range[0]).format('L')
        const toDate = moment(this.request.api.date_range[1]).format('L')
        return [fromDate, toDate]
      } else {
        return [null, null]
      }
    },

    exportAllPatients(reportType) {
      let vm = this;
      vm.loaders.exportAllPatientsLoader = true;
      const dateRangeObject = vm.getDateRangeObject()
      const config = {
        responseType: "blob",
        params: {
          firstName: vm.request.api.firstName,
          lastName: vm.request.api.lastName,
          checklist: vm.request.api.currentChecklistItem,
          currentStage: vm.request.api.currentStage,
          completedBy: vm.request.api.completedBy,
          surgeryDate: vm.request.api.surgeryDate,
          surgeryDateDuration: vm.request.api.surgeryDateDuration,
          user_sub_categories: vm.selectedPatientTags ?? [],
          report_type: reportType,
          from_date: dateRangeObject[0] ?? '',
          to_date: dateRangeObject[1] ?? ''
        }
      };
      axios.get(`${vm.$store.getters.getBaseUrl}/api/seminar2surgery/all-patients/excel`,
        config,
      ).then(response => {
        const url = URL.createObjectURL(new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }))
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Baritastic-All-Patients.xlsx`)
        document.body.appendChild(link);
        link.click();
        vm.loaders.exportAllPatientsLoader = false;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        vm.$emit('exportComplete')
      });
    },

    showSurgeryDate(surgeryDate) {
      let date = '';
      if(surgeryDate){
        date = moment(surgeryDate).format('MM/DD/YYYY');
      }
      return date;
    },

    formatDate(oldDate) {
      let date = '';
      if (oldDate) {
        date = moment(oldDate).format('MMM D, YYYY');
      }
      return date;
    },

    sortMethod({prop,order})  {
      if(order){
        this.request.api.sortBy = prop;
        this.request.api.sortByDir = order == 'ascending' ? 'ASC' : 'DESC';
      }
      else {
        this.request.api.sortBy = "days_late";
        this.request.api.sortByDir = 'ASC';
      }
      this.pagination.page = 1;
      this.createCategoriesArrAndApiCall();
    },
    updateCheckedBy(element) {
      let vm = this;
      let params = {
        id: element.id,
        checked_by: element.checked_by,
      }

      vm.$store.dispatch('_updateCheckedByInReport', params)
        .then(response => {
          let index = vm.response.tblAllPatientsData.findIndex((el) => el.id == response.data.data.id);
          vm.$set(vm.response.tblAllPatientsData, index, response.data.data);
        })
        .catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Update Checklists Items',
            message: message
          })
        })
        .finally(() => {
        })
    },
    exportAllPatientsReport(reportType) {
      const dateRangeObject = this.getDateRangeObject()
      if (!dateRangeObject[0] && !dateRangeObject[1]) {
        Swal.fire({
          title: 'Warning!',
          text: 'This report requires a date filter. Please choose a date range before running this report.',
          confirmButtonText: 'Close'
        })
        return;
      }
      this.$emit('exportStart')
      this.$store.commit('toggleExportChecklistReportModal', false)
      this.exportAllPatients(reportType)
    },

    getChecklistItemNamesForChecklistReport(search, page = 1) {
      let vm = this;
      vm.request.checklistItemOptions.search = search;
      vm.loaders.checklistOptions = true;
      const config = {
        params: {
          tab: 4,
          search: vm.request.checklistItemOptions.search,
        }
      };
      vm.$store.dispatch('_getChecklistItemNamesForChecklistReport', config)
        .then(response => {        
          vm.response.checklistItems = response.data.data.map((el) => el.item);
          vm.loaders.checklistOptions = false;
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
            vm.loaders.checklistOptions = false;
          }
        })
        .finally(() => {
        })
    },

  },
  watch : {

  }
}
</script>

<style scoped>
.date-ranger-picker {
  min-height: 43px;
}

.clear-txt {
  text-align: left;
  font: normal normal normal 9px/13px Open Sans;
  letter-spacing: 0px;
  color: #BB2255;
  cursor: pointer;
}

</style>
<style>
.radioButtonCard {
  padding: 8px 11.5px !important;
  box-shadow: 0px 3px 2px #E9ECEF0D;
  border: 1px solid #DEE2E6;
  border-radius: 8px;
  opacity: 1;
  width: 228px;
  height: 53px;
}

.radioButtonCard :focus {
  background-color: #94B527 !important;
}

.radioButtonCard .custom-control-label {
  vertical-align: unset !important;
}


.radioButtonCard .custom-radio .custom-control-input~.custom-control-label {
  margin-top: -9px;
}

.stalledPatientReminderModal .modal-body {
  padding-top: 0px;
}

.stalledPatientReminderModal .stalled-patients-reminder-message {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #4D4D4D;
  opacity: 1;
}

.stalledPatientSnoozeModal .modal-body {
  padding-top: 0px;
}

.stalledPatientSnoozeModal .stalled-patients-snooze-message {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #4D4D4D;
  opacity: 1;
}

.btn-purple {
  color: white;
  background-color: #8898aa;
  border-radius: 9px;
}
.table-stalled-patient .input_height .el-input__inner {
  width: 150px;
}
.input_height input {
  height: 28px !important;
}

.input_height .el-input__prefix {
  margin-top: -6px;
}
.input_height .el-input__suffix {
  margin-top: 2px;
}

.checklist-items-load-more {
  border-top: 1px solid rgb(213, 213, 213);
  font-size: 14px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-align: center;
}
</style>
